.login-form .emailInput,
.login-form .emailInput:-webkit-autofill,
.login-form .emailInput:focus,
.login-form .emailInput:autofill,
.login-form .passwordInput,
.login-form .passwordInput:focus,
.login-form .rememberMeCheck,
.login-form button {
    background-color: #143e57 !important;
    color: #ffffff;
    /* -webkit-text-background-color: #143e57 !important; */
}

.login-form input:-webkit-autofill,
.login-form input:-webkit-autofill:hover,
.login-form input:-webkit-autofill:focus,
.login-form input:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px #143e57 inset !important;
}

.login-form input:-webkit-autofill {
    -webkit-text-fill-color: #ffffff !important;
}