.documentDropzone {
  flex: 1 1 0%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  border-width: 2px;
  border-radius: 2px;
  border-color: #2196f3;
  border-style: dashed;
  background-color: rgb(250, 250, 250);
  color: rgb(189, 189, 189);
  outline: none;
  transition: border 0.24s ease-in-out 0s;
}
