.btn-scroll-to-top.hidden {
    bottom: -56px;
    opacity: 0;
}

.btn-scroll-to-top {
    /* background: var(--bs-yellow); */
    /* color: var(--button-color); */
    border-radius: 50%;
    bottom: 20px;
    box-shadow: 0 2px 5px 0 rgb(0 0 0 / 26%);
    cursor: pointer;
    display: block;
    height: 56px;
    opacity: 1;
    outline: 0;
    position: fixed;
    right: 20px;
    transition: bottom .2s, opacity .2s;
    user-select: none;
    width: 56px;
    z-index: 1;
}

.icon-scroll-to-top {
    font-size: 20pt;
}